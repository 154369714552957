import React from 'react';
import '../../css/home/ColorLine.css';
import Colors from '../../utils/Colors';

/**
 * Color lines for homepage
 */
class ColorLine extends React.Component {

	render() {
        
		let svgStyle = {
			margin: '0',
			padding: '0',
			width: this.props.width,
			height: this.props.height,
			position: 'absolute',
			top: 'calc(' + this.props.top + ' - ' + this.props.marginTop + ')',
			left: this.props.left,
			transform: 'translateX(' + this.props.translateX + ')',
			zIndex: '2'
		};

		let containerStyle = {
			width: 'calc(100% - ' + this.props.marginLeft + ' - ' + this.props.marginRight + ')',
			height: 'calc(100% - ' + this.props.marginTop + ' - ' + this.props.marginBottom + ')',
			overflow: this.props.overflow,
			position: 'absolute',
			top: this.props.marginTop,
			left: this.props.marginLeft
		};

		let lineColor = {
			stroke: Colors.getColorHexaByName(this.props.color),
			strokeLinecap: 'square'
		};

		let lineSVG = (
			<div style={containerStyle} className={this.props.className}>
				<div style={svgStyle}>
					<svg className="color-line-container" preserveAspectRatio="none">
						<line x1={0} y1={0} x2={'100%'} y2={'100%'} className="color-line" style={lineColor} />
					</svg>
				</div>
			</div>
		);

		return lineSVG;
	}
}

export default ColorLine;
