import React from 'react';
import { graphql } from 'gatsby';
import Row from 'react-bootstrap/Row';
import IntroductionSection from '../components/home/IntroductionSection';
import Footer from '../components/menu/Footer';
import NumbersSection from '../components/home/NumbersSection';
//import TitleSection from "../components/home/TitleSection";
import NewsSection from '../components/home/NewsSection';
import ColorLine from '../components/home/ColorLine';
import MainLayout from '../components/MainLayout';
import { Element } from 'react-scroll';
//import ColorLine from "../components/home/ColorLine";
import VideoSection from '../components/home/VideoSection';

/**
 * Homepage
 */
class Home extends React.Component {

	/**
	 * Constructor
	 * @param {*} props Component props
	 */
	constructor(props) {
		super(props);

		this.state = {
			// is scroll snaping activated (hoomepage and bigscreen)
			isScrollSnaping: true,
			// is displayed in small version (small screens)
			smallVersion: false
		};

		this.setNewState = this.setNewState.bind(this);
	}

	/**
	 * Apply new state
	 * @param {*} newState State to apply
	 */
	setNewState(newState) {
		this.setState(newState);
	}

	render() {
		/*let titleSectionData = this.props.data.allKontentItemHomepage.edges[0]
			.node.elements.photo_section.value[0].elements;*/

		let titleSectionData = this.props.data.allKontentItemHomepage.edges[0].node.elements.video_section.value[0].elements;
		let introSectionData = this.props.data.allKontentItemHomepage.edges[0].node.elements.introduction_section.value[0].elements;
		let numbersSectionData = this.props.data.allKontentItemHomepage.edges[0].node.elements.numbers_section.value[0].elements;

		let footerSnap = '';
		if (this.state.isScrollSnaping && !this.state.smallVersion) {
			footerSnap = <Footer snap={true} smallVersion={this.state.smallVersion} />;
		}

		return (
			<MainLayout setNewState={this.setNewState} scrollSnap={true} activeItem={-1} className="homepage">
				{/* Video Section */}
				<Row id="row1" className="home fullheight">
					<Element name="e-row1" />
					{/*<TitleSection
						background={titleSectionData.background.value[0].url}
						title={titleSectionData.title.value}
						lineColor="emg-yellow"
						index={1}
					/>*/}
					<VideoSection
						background={titleSectionData.video.value[0].url}
						backgroundImage={titleSectionData.background.value[0].url}
						title={titleSectionData.title.value}
						text={titleSectionData.subtitle.value}
						lineColor="emg-yellow"
						index={1}
					/>
				</Row>
				{/* Intro Section */}
				<Row id="row2" className={this.state.smallVersion ? '' : 'fullheight'}>
					<Element name="e-row2" />
					<IntroductionSection
						title={introSectionData.title.value}
						text={introSectionData.text.value}
						photoUrl={introSectionData.picture.value[0].url}
					/>
				</Row>
				{/* Numbers Section */}
				<Row id="row3" className={!this.state.isScrollSnaping ? '' : 'fullheight'}>
					<Element name="e-row3" />
					<ColorLine
						className="hidden-big-version"
						left="0%"
						top="-350px"
						width="100%"
						height="700px"
						marginTop="0px"
						marginBottom="0px"
						marginLeft="0px"
						marginRight="0px"
						color="emg-pink"
						overflow="visible"
					/>
					<ColorLine
						className="hidden-big-version"
						left="0%"
						top="-385px"
						width="100%"
						height="700px"
						marginTop="0px"
						marginBottom="0px"
						marginLeft="0px"
						marginRight="0px"
						color="emg-yellow"
						overflow="visible"
					/>
					<ColorLine
						className="hidden-big-version"
						left="0%"
						top="-325px"
						width="100%"
						height="700px"
						marginTop="0px"
						marginBottom="0px"
						marginLeft="0px"
						marginRight="0px"
						color="emg-blue"
						overflow="visible"
					/>

					<NumbersSection
						title={numbersSectionData.title.value}
						numbers={numbersSectionData.numbers.value}
						description={numbersSectionData.description.value}
						background={numbersSectionData.background.value[0].url}
					/>
				</Row>
				{/* News Section */}
				<Row id="row4" className={!this.state.isScrollSnaping ? '' : 'fullheight'}>
					<Element name="e-row4" />
					<NewsSection title="Follow Us">{footerSnap}</NewsSection>
				</Row>
			</MainLayout>
		);
	}
}

export default Home;

export const query = graphql`
	query Home {
		allKontentItemHomepage {
			edges {
				node {
					elements {
						introduction_section {
							value {
								... on kontent_item_introductionbloc {
									elements {
										picture {
											value {
												url
											}
										}
										text {
											value
										}
										title {
											value
										}
									}
								}
							}
						}
						numbers_section {
							value {
								... on kontent_item_numberssection {
									elements {
										title {
											value
										}
										description {
											value
										}
										background {
											value {
												url
											}
										}
										numbers {
											value {
												... on kontent_item_simplenumber {
													elements {
														description {
															value
														}
														number {
															value
														}
														title {
															value
														}
													}
													internal {
														type
													}
												}
											}
										}
									}
								}
							}
						}
						video_section {
							value {
								... on kontent_item_videosection {
									elements {
										subtitle {
											value
										}
										title {
											value
										}
										video {
											value {
												url
											}
										}
										background {
											value {
												url
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
