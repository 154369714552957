import React from 'react';
import '../../../css/home/numbers/SimpleNumber.css';

/** 
 * Number component
 */
class SimpleNumber extends React.Component {
	
	render() {
		let title = '';

		if (this.props.title) {
			let spacer = '';
			if (this.props.description) {
				spacer = ' ';
			}
			title = <span className="numbers-title">{spacer + this.props.title}</span>;
		}

		return (
			<div className={this.props.classToAdd + ' numbers-simplenumber d-flex align-items-center justify-content-center flex-column'}>
				<div>
					<span className="numbers-number">{this.props.number}</span>
					{title}
				</div>

				<div className="numbers-subtitle">{this.props.description}</div>
			</div>
		);
	}
}

export default SimpleNumber;
