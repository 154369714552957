import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Colors from '../../utils/Colors';
import '../../css/home/TitleSection.css';
import MenuBurgerButton from '../menu/MenuBurgerButton';

/**
 * TitleSection with vidéo on background
 */
class VideoSection extends React.Component {
	
	render() {
		let customBG = {
			backgroundImage: 'url(' + this.props.backgroundImage + ')'
		};

		let customLineColor = {};
		if (this.props.lineColor) {
			customLineColor = { border: 'solid 2px ' + Colors.getColorHexaByName(this.props.lineColor) };
		}

		let indexBloc = '';
		if (this.props.index) {
			let indexStr = this.props.index;

			if (this.props.index < 10) indexStr = '0' + this.props.index;

			indexBloc = <div className="titleSection-index section-index">{indexStr}</div>;
		}

		let textBloc = '';

		if (this.props.text) {
			textBloc = <div className="title-subtitle emg-text">{this.props.text}</div>;
		}

		return (
			<div className="titleSection section d-flex align-items-end " style={customBG}>
				<video autoPlay={true} loop={true} muted className="titleSection-video">
					<source src={this.props.background} />
				</video>

				<div className="blackfilter" />

				{indexBloc}

				<div className="lighttriangle">
					<svg style={{ width: '100%', height: '100%' }} viewBox="0 0 100 100" preserveAspectRatio="none">
						<polygon points="0,0 100,100 0,100" fill="white" />
					</svg>
				</div>

				<div className="lighttriangle2">
					<svg style={{ width: '100%', height: '100%' }} viewBox="0 0 100 100" preserveAspectRatio="none">
						<polygon points="0,0 100,0 100,100" fill="white" />
					</svg>
				</div>

				
				<MenuBurgerButton key="burger-btn-video" className="hidden-small-version hidden-nosnap-version hidden-1220px" />

				<Container fluid className="no-gutters">
					<Row>
						<Col
							xl={{ span: 6, offset: 1 }}
							lg={{ span: 7, offset: 1 }}
							md={{ span: 8, offset: 1 }}
							xs={{ span: 8, offset: 1 }}
							className="titleSection-title">
							{this.props.title}

							<div className="titleline" style={customLineColor} />

							{textBloc}
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default VideoSection;
