import React from 'react';
import Logo from '../menu/Logo';
import MenuBurgerButton from '../menu/MenuBurgerButton';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../css/home/NewsSection.css';
import '../../css/home/juicer/JuicerCustom.css';

/**
 * News bloc (Juicer) for homepage
 */
class NewsSection extends React.Component {

	constructor(props) {
		super(props);

		this.juicer2 = React.createRef();
		this.juicer1 = React.createRef();
		this.juicerSmall = React.createRef();

		this.checkJuicer = this.checkJuicer.bind(this);

	}

	componentWillUnmount(){
		/*window.removeEventListener('resize', this.resize);*/
		window.Juicer.remove();
	}

	componentDidMount(){
		//window.Juicer.initialize();
		/*window.addEventListener('resize', this.resize);*/
		console.log("MOUNT");
		//setTimeout(this.checkJuicer, 1000);
	}

	checkJuicer(){
		let ok = true;

		if (this.juicer2.current.childNodes.length < 1){
			this.juicer2.current.className = "juicer-2-news juicer-feed";
			ok = false;
			console.log("JUICER 2 OUT")
		}
		if (this.juicer1.current.childNodes.length < 1){
			this.juicer1.current.className = "juicer-1-news juicer-feed";
			ok = false;
			console.log("JUICER 1 OUT")
		}
		if (this.juicerSmall.current.childNodes.length < 1){
			this.juicerSmall.current.className = "small-version juicer-feed";
			ok = false;
			console.log("JUICER SMALL OUT")
		}

		if (!ok){
			console.log("NOT OK !");
			window.Juicer.remove();
			window.Juicer.initialize();
			setTimeout(this.checkJuicer, 1000);
		}
		//console.log(this.juicer2.current.className);
	}

	componentDidUpdate(){
		console.log("UPDATE");
		//window.Juicer.initialize();
		setTimeout(this.checkJuicer, 1000);
	}

	resize(){
		//window.Juicer.initialize();
		//j-initialized j-modern j-desktop slider hover-images loaded
		
	}

	checkJuicerFeeds(){
		
	}

	checkJuicerFeed(feed){

	}

	render() {
		return (
			<div className="newsSection section d-flex align-items-start">
				<Logo key="logo-news" className="hidden-small-version" />
				<MenuBurgerButton key="burger-btn-news" className="hidden-small-version hidden-nosnap-version" />

				<div className="newsSection-index section-index">04</div>

				<Container fluid className="newsSection-content">
					<Row>
						<Col md={{ span: 8, offset: 1 }} sm={{ span: 10, offset: 1 }} xs={{ span: 10, offset: 1 }} className="emg-title">
							{this.props.title}
						</Col>
					</Row>
					<Row className="mt-5">
						<Col className="juicer-col" xs={{ span: 10, offset: 1 }}>
							{/* 3 Juicer blocks : one big version, one medium and one small. CSS displays only the good one.*/}
							<ul className={'juicer-2-news juicer-feed'} data-feed-id="https-www-linkedin-com-company-549468" data-columns="2" ref={this.juicer2}/>
							<ul className={'juicer-1-news juicer-feed'} data-feed-id="https-www-linkedin-com-company-549468" data-columns="1" ref={this.juicer1} />
							<ul className={'small-version juicer-feed'} data-feed-id="https-www-linkedin-com-company-549468" data-columns="1" ref={this.juicerSmall} />
						</Col>
					</Row>
				</Container>
				{this.props.children}
				{/*<div className="newsSection section">
				
				

				
		</div>*/}
			</div>
		);
	}
}

export default NewsSection;
