import React from 'react';
import SimpleNumber from './numbers/SimpleNumber';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../css/home/NumbersSection.css';
import Logo from '../menu/Logo';
import MenuBurgerButton from '../menu/MenuBurgerButton';
import ColorLine from './ColorLine';
import Breakpoint from '../../utils/Breakpoints';

/**
 * Numbers section for homepage
 */
class NumbersSection extends React.Component {

	/**
     * Constructor
     * @param {*} props Component props
     */
	constructor(props) {
		super(props);

		// Init state
		this.state = {
			// number of numbers on a line
			nbNumbersPerLine: 3
		};

		this.checkChangeNumbersPerLine = this.checkChangeNumbersPerLine.bind(this);
	}

	componentDidMount() {

		// Subscribre to breakpoint change
		Breakpoint.init();
		Breakpoint.subscribeSpecific('md', this.checkChangeNumbersPerLine);

		this.checkChangeNumbersPerLine(Breakpoint.breakpointsValues);
	}

	/**
	 * Check if screen size is going from big to small or from small to big
	 * @param {*} breakpoints Breakpoints object values
	 */
	checkChangeNumbersPerLine(breakpoints) {
		// if big : 3 numbers
		if (!breakpoints.md) {
			this.changeNumbersPerLine(3);
		}
		// if small : 2 numbers
		else {
			this.changeNumbersPerLine(2);
		}
	}

	/**
	 * Set the number of numbers to display on a line
	 * @param {int} nbNumbersPerLine Number of numbers on a line to set
	 */
	changeNumbersPerLine(nbNumbersPerLine) {
		if (this.state.nbNumbersPerLine !== nbNumbersPerLine) {
			this.setState({
				nbNumbersPerLine: nbNumbersPerLine
			});
		}
	}

	render() {
		let customBG = {
			backgroundImage: 'url(' + this.props.background + ')'
		};

		return (
			<div className="numbersSection section d-flex align-items-start" style={customBG}>
				<div className="lightfilter" />

				{/* black triangle background on the right */}
				<div className="hidden-small-version numbersSection-dark-triangle">
					<svg style={{ width: '100%', height: '100%', position: 'absolute' }} viewBox="0 0 100 100" preserveAspectRatio="none">
						<defs>
							<clipPath id="shape">
								<polygon points="0,0 100,0 100,100" fill="#141417" />
							</clipPath>
						</defs>
						<polygon points="0,0 100,0 100,100" fill="#141417" />
					</svg>
					{/* Section number for big version*/}
					<div className="numbersSection-index">
						<svg style={{ width: '100%', height: '100%', position: 'absolute', left: '0px' }}>
							<text x="0" y="672" fontFamily="Six Caps" fontSize="672" fill="white">
								03
							</text>
						</svg>
					</div>

					{/*<div className="numbersSection-index section-index">
						03
					</div>*/}
				</div>
				{/* Section number for small version */}
				<div className="numbersSection-index section-index hidden-big-version">03</div>

				<Logo key="logo-numbers" className="hidden-small-version" />
				<MenuBurgerButton key="burger-btn-numbers" className="hidden-small-version hidden-nosnap-version" />

				{/* Color lines for big version */}
				<ColorLine className="hidden-small-version" left="0%" top="-190vh"
					width="140vw" height="400vh" marginTop="0px" marginBottom="22vh"
					marginLeft="0px" marginRight="5vw" color="emg-pink" overflow="hidden"
				/>
				<ColorLine className="hidden-small-version" translateX="2vw" left="0%" top="-190vh" 
					width="140vw" height="400vh" marginTop="0px" marginBottom="52vh"
					marginLeft="0px" marginRight="10vw" color="emg-yellow" overflow="hidden"
				/>
				<ColorLine className="hidden-small-version" translateX="-1.5vw" left="0%"
					top="-190vh" width="140vw" height="400vh" marginTop="0px" marginBottom="34vh"
					marginLeft="0px" marginRight="7.5vw" color="emg-blue" overflow="hidden"
				/>

				<Container fluid className="numbersSection-content">
					<Row>
						<Col md={{ span: 8, offset: 1 }} sm={{ span: 10, offset: 1 }} xs={{ span: 10, offset: 1 }} className="emg-title">
							{this.props.title}
						</Col>
					</Row>
					<Row>
						<Col
							xl={{ span: 8, offset: 1 }}
							lg={{ span: 9, offset: 1 }}
							md={{ span: 10, offset: 1 }}
							sm={{ span: 10, offset: 1 }}
							xs={{ span: 10, offset: 1 }}>
							<div className="numbersSection-numbers-container d-flex align-items-center emg-title justify-content-around">
								{this.props.numbers.map((number, index) => {
									let breakDiv = '';
									if ((index + 1) % this.state.nbNumbersPerLine === 0) {
										breakDiv = <div className="numbersSection-flexbreak" key={index + 'break'} />;
									}

									let snumber = (
										<SimpleNumber
											number={number.elements.number.value}
											title={number.elements.title.value}
											description={number.elements.description.value}
											key={'number' + index}
											classToAdd={''}
										/>
									);

									return [ snumber, breakDiv ];
								})}
							</div>
						</Col>
					</Row>
					<Row>
						<Col
							xl={{ span: 8, offset: 1 }}
							md={{ span: 9, offset: 1 }}
							sm={{ span: 10, offset: 1 }}
							xs={{ span: 10, offset: 1 }}
							className="emg-text">
							{this.props.description}
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default NumbersSection;
