import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../css/home/IntroSection.css';
import Logo from '../menu/Logo';
import MenuBurgerButton from '../menu/MenuBurgerButton';
import ColorLine from './ColorLine';

/**
 * Introduction section for homepage
 */
class IntroductionSection extends React.Component {

	render() {
		let customBG = {
			backgroundImage: 'url(' + this.props.photoUrl + ')'
		};

		return (
			<div className="introSection section d-flex align-items-center" style={customBG}>
				{/* light grey filter on the background image */}
				<div className="lightfilter" />
				{/* left column background */}
				<div className="darkpolygon hidden-small-version">
					<svg style={{ width: '100%', height: '100%' }} viewBox="0 0 100 100" preserveAspectRatio="none">
						<polygon points="0,0 47.368,0 100,100 0,100" fill="#141417" />
					</svg>
				</div>
				
				<Logo key="logo-intro" className="hidden-small-version" />
				<MenuBurgerButton key="burger-btn-intro" className="hidden-small-version hidden-nosnap-version" />
				
				{/* 3 color lines for big screens */}
				<ColorLine className="hidden-small-version" translateX="0px" left="0%"
					top="-90vh" width="140vw" height="400vh" marginTop="12vh" marginBottom="0px"
					marginLeft="0px" marginRight="0px" color="emg-pink" overflow="hidden"
				/>
				<ColorLine className="hidden-small-version" translateX="2vw" left="0%"
					top="-90vh" width="140vw" height="400vh" marginTop="20vh" marginBottom="0px"
					marginLeft="0px" marginRight="0px" color="emg-yellow" overflow="hidden"
				/>
				<ColorLine className="hidden-small-version" translateX="-1.5vw" left="0%"
					top="-90vh" width="140vw" height="400vh" marginTop="35vh" marginBottom="0px"
					marginLeft="0px" marginRight="0px" color="emg-blue" overflow="hidden"
				/>

				{/* background number */}
				<div className="introSection-index section-index">02</div>

				<Container fluid className="introSection-content">
					<Row>
						<Col
							xl={{ span: 4, offset: 1 }}
							lg={{ span: 4, offset: 1 }}
							md={{ span: 7, offset: 1 }}
							xs={{ span: 10, offset: 1 }}
							className="emg-title">
							{this.props.title}
						</Col>
					</Row>
					<Row className="introSection-text">
						<Col xl={{ span: 3, offset: 1 }} lg={{ span: 4, offset: 1 }} md={{ span: 7, offset: 1 }} xs={{ span: 10, offset: 1 }}>
							<div
								className="emg-text"
								dangerouslySetInnerHTML={{
									__html: this.props.text
								}}
							/>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default IntroductionSection;
